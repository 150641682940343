@media (min-width: 1920px) {
  :root {
    font-size: 13pt;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  :root {
    font-size: 12pt;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  :root {
    font-size: 11pt;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  :root {
    font-size: 8pt;
  }
}
@media (max-width: 480px) {
  :root {
    font-size: 6pt;
  }
}

:root {
  --hexW: 12rem;
  --hexH: calc(var(--hexW) / 1.1547005);
  --hexPadding: 0px;
  --hexMarginLeft: calc(var(--hexW) * 1.5);
  --hexMarginTop: 0px;
  --backgroundColorLight: #505f59;
  --backgroundColorDark: #20094d;
  --textBackgroundColor: #daf8ff;
  --hexColor: #6397e0;
  --hexHoverColor: #aaddf7;
  --textColor: #0f193f;
}

.band-img {
  width: 37rem;
  height: 17.5rem;
}

.symmetry-image {
  width: 37rem;
  height: 23.9rem;
}

p,
h1,
h2,
h3,
h4,
li {
  color: var(--textColor);
}

p,
li {
  font-size: 0.9rem;
  line-height: 1.5rem;
  opacity: 0.9;
}

li {
  top: -0.9rem;
  margin-left: 0rem;
}

ul {
  margin-left: 0rem;
  list-style-type: circle;
  padding-left: 2.5rem;
}

.resume-h4 {
  margin-bottom: -0.4rem;
  font-size: 1.1rem;
}

.resume-h2 {
  margin-bottom: -0.2rem;
  font-size: 1.45rem;
}

.resume-dates {
  margin-top: 0.2rem;
  margin-bottom: -0.8rem;
  font-size: 0.76rem;
  font-style: italic;
}

hr {
  background-color: var(--textColor);
  border: hidden;
  height: 0.1rem;
  margin-bottom: -0.6rem;
  opacity: 0.4;
}

path,
text {
  fill: var(--textColor);
}

text {
  font-size: 45px;
  text-anchor: middle;
}

#label-text {
  font-weight: 900;
}

#main-container {
  background-image: linear-gradient(
      41deg,
      rgba(107, 107, 107, 0.04) 0%,
      rgba(107, 107, 107, 0.04) 8%,
      rgba(31, 31, 31, 0.04) 8%,
      rgba(31, 31, 31, 0.04) 100%
    ),
    linear-gradient(
      9deg,
      rgba(228, 228, 228, 0.04) 0%,
      rgba(228, 228, 228, 0.04) 62%,
      rgba(54, 54, 54, 0.04) 62%,
      rgba(54, 54, 54, 0.04) 100%
    ),
    linear-gradient(
      124deg,
      rgba(18, 18, 18, 0.04) 0%,
      rgba(18, 18, 18, 0.04) 37%,
      rgba(233, 233, 233, 0.04) 37%,
      rgba(233, 233, 233, 0.04) 100%
    ),
    linear-gradient(
      253deg,
      rgba(201, 201, 201, 0.04) 0%,
      rgba(201, 201, 201, 0.04) 55%,
      rgba(47, 47, 47, 0.04) 55%,
      rgba(47, 47, 47, 0.04) 100%
    ),
    linear-gradient(
      270deg,
      rgba(172, 172, 172, 0.04) 0%,
      rgba(172, 172, 172, 0.04) 33%,
      rgba(26, 26, 26, 0.04) 33%,
      rgba(26, 26, 26, 0.04) 100%
    ),
    linear-gradient(
      64deg,
      rgba(11, 11, 11, 0.04) 0%,
      rgba(11, 11, 11, 0.04) 38%,
      rgba(87, 87, 87, 0.04) 38%,
      rgba(87, 87, 87, 0.04) 100%
    ),
    linear-gradient(
      347deg,
      rgba(199, 199, 199, 0.04) 0%,
      rgba(199, 199, 199, 0.04) 69%,
      rgba(4, 4, 4, 0.04) 69%,
      rgba(4, 4, 4, 0.04) 100%
    ),
    linear-gradient(
      313deg,
      rgba(36, 36, 36, 0.04) 0%,
      rgba(36, 36, 36, 0.04) 20%,
      rgba(91, 91, 91, 0.04) 20%,
      rgba(91, 91, 91, 0.04) 100%
    ),
    linear-gradient(90deg, rgb(10, 17, 72), rgb(35, 148, 228));
  /*background-image: url("https://raw.githubusercontent.com/DerekDardzinski/PersonalWebsite/master/src/components/images/grapheneBackground2.jpg");*/
  background-size: 100% 100%;
  height: 100vh;
  width: 100%;
  font-family: "Montserrat", serif;
  color: rgba(252, 255, 245, 1);
  position: relative;
  overflow: auto;
}

.main {
  width: calc(4 * var(--hexW));
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

#main-top {
  height: calc(2 * var(--hexH));
  margin-top: calc(0.15 * var(--hexH));
}

#main-middle {
  height: auto;
}

#main-bottom {
  height: calc(2 * var(--hexH));
  margin-bottom: calc(0.15 * var(--hexH));
}

.background {
  width: calc(3.455 * var(--hexW));
  left: calc(50% - 1.73 * var(--hexW));
  background-color: var(--textBackgroundColor);
}
#background-top {
  position: absolute;
  top: calc(0.99 * var(--hexH));
  height: calc(1.01 * var(--hexH));
}
#background-middle {
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 2rem;
  box-sizing: border-box;
  outline: solid;
  outline-offset: -0.75rem;
  outline-width: 0.15rem;
  outline-color: var(--textColor);
}

#background-bottom {
  position: absolute;
  height: calc(1.01 * var(--hexH));
}

.name {
  position: relative;
  margin-top: calc(var(--hexH) * 0.64);
  text-align: center;
  font-size: 2.25rem;
}

.school {
  text-align: center;
  margin-top: calc(var(--hexH) * 0.1);
}

.toplogo {
  position: absolute;
  margin-top: calc(var(--hexH) * 0.15);
  width: calc(var(--hexW) * 0.35);
  margin-left: calc(var(--hexW) * 1.56);
}

.bottomlogo {
  position: absolute;
  margin-top: calc(var(--hexH) * 0);
  width: calc(var(--hexW) * 0.35);
  margin-left: calc(var(--hexW) * 1.56);
}

polygon {
  fill: var(--hexColor);
  stroke: var(--textColor);
  stroke-width: 8;
}

svg:hover > polygon {
  fill: var(--hexHoverColor);
  cursor: pointer;
}

svg:hover > a > polygon {
  fill: var(--hexHoverColor);
  cursor: pointer;
}

p {
  margin: 1px;
}

g {
  fill: var(--textColor);
  fill-opacity: 1;
}

.hexagon {
  width: var(--hexW);
  height: var(--hexH);
  padding: var(--hexPadding);
  position: absolute;
}

#center-top {
  margin-left: var(--hexMarginLeft);
  margin-top: var(--hexMarginTop);
}

#left1-down1 {
  margin-left: calc(var(--hexMarginLeft) - 0.74 * var(--hexW));
  margin-top: calc(var(--hexMarginTop) + 0.49 * var(--hexH));
}

#left2-down2 {
  margin-left: calc(var(--hexMarginLeft) - 2 * 0.74 * var(--hexW));
  margin-top: calc(var(--hexMarginTop) + 2 * 0.49 * var(--hexH));
}

#right1-down1 {
  margin-left: calc(var(--hexMarginLeft) + 0.74 * var(--hexW));
  margin-top: calc(var(--hexMarginTop) + 0.49 * var(--hexH));
}

#right2-down2 {
  margin-left: calc(var(--hexMarginLeft) + 2 * 0.74 * var(--hexW));
  margin-top: calc(var(--hexMarginTop) + 2 * 0.49 * var(--hexH));
}

#center-bottom {
  margin-left: var(--hexMarginLeft);
  margin-top: var(--hexH);
}

#left1-down1-bottom {
  margin-left: calc(var(--hexMarginLeft) - 0.74 * var(--hexW));
  margin-top: calc(var(--hexMarginTop) + 0.51 * var(--hexH));
}

#left2-down0-bottom {
  margin-left: calc(var(--hexMarginLeft) - 2 * 0.74 * var(--hexW));
  margin-top: calc(0.02 * var(--hexH));
}

#right1-down1-bottom {
  margin-left: calc(var(--hexMarginLeft) + 0.74 * var(--hexW));
  margin-top: calc(var(--hexMarginTop) + 0.51 * var(--hexH));
}

#right2-down0-bottom {
  margin-left: calc(var(--hexMarginLeft) + 2 * 0.74 * var(--hexW));
  margin-top: calc(0.02 * var(--hexH));
}
